import React from "react";
import {observer} from "mobx-react";
import {Link, matchPath, withRouter} from "react-router-dom";

import {Breadcrumb} from 'antd';

import store from './BreadcrumbsStore';
import LoginService from "../services/Login";

const {Item} = Breadcrumb;

function isMatch(pathname, pattern) {
    return matchPath(pathname, {
        path: pattern,
        exact: false,
        strict: true
    });
}

function isExactMatch(pathname, pattern) {
    return matchPath(pathname, {
        path: pattern,
        exact: true,
        strict: true
    });
}

const bStyle = {margin: '16px 0'};

const BreadcrumbsFromPath = observer(({pathname}) => {
    const p = pathname;
    const role=LoginService.getCurrentUserRole();
    const isAdmin=role==='admin';
    let m;
    if (p.startsWith("/t/courses")) {
        return <Breadcrumb style={bStyle}>
            <Item><Link to="/t/courses">我的课程</Link></Item>
        </Breadcrumb>
    } else if (isMatch(p, "/t/course/:courseId")) {
        return <Breadcrumb style={bStyle}>
            {isAdmin?<Item><Link to="/a/courses">课程</Link></Item>:<Item><Link to="/t/courses">我的课程</Link></Item>}
            <Item>{store.currentCourse}</Item>
        </Breadcrumb>
    } else if (isMatch(p, "/a/courses/")) {
        return <Breadcrumb style={bStyle}>
            <Item>课程</Item>
        </Breadcrumb>
    }
    else if (isMatch(p, "/t/course_class/:id")) {
        return <Breadcrumb style={bStyle}>
            <Item><Link to="/t/courses">我的课程</Link></Item>
            <Item><Link to={"/t/course/" + store.course._id}>{store.course.name}</Link></Item>
            <Item><Link to={"/t/course/" + store.course._id + "/classes"}>教学班级</Link></Item>
            <Item>{store.courseClass.name}</Item>
        </Breadcrumb>
    }
    else if (isMatch(p, "/t/course_class_view_student/:username/:id")) {
        return <Breadcrumb style={bStyle}>
            <Item><Link to="/t/courses">我的课程</Link></Item>
            <Item><Link to={"/t/course/" + store.course._id}>{store.course.name}</Link></Item>
            <Item><Link to={"/t/course_class/" + store.courseClass._id}>{store.courseClass.name}</Link></Item>
            <Item>{store.viewStudentUsername}</Item>
        </Breadcrumb>
    }
    else if (isMatch(p, "/t/course_class_edit/:id")) {
        return <Breadcrumb style={bStyle}>
            <Item><Link to="/t/courses">我的课程</Link></Item>
            <Item><Link to={"/t/course/" + store.course._id}>{store.course.name}</Link></Item>
            <Item><Link to={"/t/course/" + store.course._id + "/classes"}>教学班级</Link></Item>
            <Item>{store.courseClass.name}</Item>
        </Breadcrumb>
    }
    else if (p.startsWith("/s/courses")) {
        return <Breadcrumb style={bStyle}>
            <Item>我的课程</Item>
        </Breadcrumb>
    }
    else if (isMatch(p, "/s/course/:courseId")) {
        return <Breadcrumb style={bStyle}>
            <Item><Link to="/s/courses">我的课程</Link></Item>
            <Item>{store.currentCourse}</Item>
        </Breadcrumb>
    }
    else if (isMatch(p, "/t/report/boxes")) {
        return <Breadcrumb style={bStyle}>
            <Item>学生作业</Item>
        </Breadcrumb>
    } else if (isMatch(p, "/t/report/box_setting/:id")) {
        return <Breadcrumb style={bStyle}>
            <Item><Link to="/t/report/boxes">学生作业</Link></Item>
            <Item>作业箱设置</Item>
        </Breadcrumb>
    }
    else if (isMatch(p, "/t/user_group/:school/:name")) {
        return <Breadcrumb style={bStyle}>
            <Item><Link to="/t/report/boxes">学生作业</Link></Item>
            <Item><Link to={"/t/report/box_setting/"+store.boxId}>作业箱设置</Link></Item>
            <Item>用户</Item>
        </Breadcrumb>
    }
    else if (isMatch(p, "/t/report/box/:boxId")) {
        return <Breadcrumb style={bStyle}>
            <Item><Link to="/t/report/boxes">学生作业</Link></Item>
            <Item>{store.boxName}</Item>
        </Breadcrumb>
    } else if (isMatch(p, "/s/report/boxes")) {
        return <Breadcrumb style={bStyle}>
            <Item>提交作业</Item>
        </Breadcrumb>}
    else if (m = isExactMatch(p, "/s/report/box/:id")) {
            return <Breadcrumb style={bStyle}>
                <Item><Link to="/s/report/boxes">提交作业</Link></Item>
                <Item>{store.boxName}</Item>
            </Breadcrumb>
        }
    else if (m = isExactMatch(p, "/t/report/comment/:reportId/:boxId/:orderField/:orderMethod") || isExactMatch(p, "/t/report/commentview/:reportId/:boxId")) {
        return <Breadcrumb style={bStyle}>
            <Item><Link to={`/t/report/box/${m.params.boxId}`}>作业箱： {store.boxName}</Link></Item>
            <Item>{store.nextReportId ? (<Link
                to={`/t/report/comment/${store.nextReportId}/${m.params.boxId}/${m.params.orderField}/${m.params.orderMethod}`}>下一份：{store.nextReportName}</Link>) : "下一份：没有了"}</Item>
        </Breadcrumb>
    } else if (m = isExactMatch(p, "/s/report/commentview/:reportId/:boxId")) {
        return <Breadcrumb style={bStyle}>
            <Item><Link to={`/s/report/box/${m.params.boxId}`}>作业箱： {store.boxName}</Link></Item>
            <Item>{store.nextReportId ? (<Link
                to={`/s/report/commentview/${store.nextReportId}/${m.params.boxId}`}>下一份：{store.nextReportName}</Link>) : "下一份：没有了"}</Item>
        </Breadcrumb>
    }
    else if (isMatch(p, "/t/devices")) {
        return <Breadcrumb style={bStyle}>
            <Item>在线实验</Item>
        </Breadcrumb>
    }
    else if (isMatch(p, "/t/online")) {
        return <Breadcrumb style={bStyle}>
            <Item>在线演示实验</Item>
        </Breadcrumb>
    }
    else if (m = isExactMatch(p, "/t/device_setting/:id")) {
        return <Breadcrumb style={bStyle}>
            <Item><Link to="/t/devices">在线实验</Link></Item>
            <Item>实验设置</Item>
        </Breadcrumb>
    }
    else if (m = isExactMatch(p, "/t/exps/:id")) {
        return <Breadcrumb style={bStyle}>
            <Item><Link to="/t/devices">在线实验</Link></Item>
            <Item>{store.courseName}</Item>
        </Breadcrumb>
    }
    else if (m = isExactMatch(p, "/t/report/comment")) {
        return <Breadcrumb style={bStyle}>
            <Item>常用评语</Item>
        </Breadcrumb>
    }
    else if (isMatch(p, "/s/resource/manage")) {
        return <Breadcrumb style={bStyle}>
            <Item>资源管理</Item>
        </Breadcrumb>
    }
    else if (isMatch(p,"/t/user_lists")) {
        return <Breadcrumb style={bStyle}>
            <Item>名单</Item>
        </Breadcrumb>
    }
    else if (isMatch(p, "/a/i_student")) {
        return <Breadcrumb style={bStyle}>
            <Item><Link to="/a/students">学生</Link></Item>
            <Item>导入学生</Item>
        </Breadcrumb>
    }
    else if (isMatch(p, "/a/i_teacher")) {
        return <Breadcrumb style={bStyle}>
            <Item><Link to="/a/teachers">教师</Link></Item>
            <Item>导入教师</Item>
        </Breadcrumb>
    }
    else if (isMatch(p, "/a/i_admin")) {
        return <Breadcrumb style={bStyle}>
            <Item><Link to="/a/admin">管理员</Link></Item>
            <Item>导入管理员</Item>
        </Breadcrumb>
    }
    else if (isMatch(p, "/a/i_user_group")) {
        return <Breadcrumb style={bStyle}>
            <Item><Link to="/a/user_groups">用户组</Link></Item>
            <Item>导入用户组</Item>
        </Breadcrumb>
    }
    else if (isMatch(p, "/a/i_teacher_box")) {
        return <Breadcrumb style={bStyle}>
            <Item><Link to="/a/experiment_box">教师实验箱管理</Link></Item>
            <Item>导入教师实验箱</Item>
        </Breadcrumb>
    }
    else if (isMatch(p, "/a/i_user_group")) {
        return <Breadcrumb style={bStyle}>
            <Item>导入用户组</Item>
        </Breadcrumb>
    }
    else if (isMatch(p, "/a/update")) {
        return <Breadcrumb style={bStyle}>
            <Item>版本更新</Item>
        </Breadcrumb>
    }
    else if (isMatch(p, "/a/students")) {
        return <Breadcrumb style={bStyle}>
            <Item>学生</Item>
        </Breadcrumb>
    }
    else if (isMatch(p, "/a/course_copy")) {
        return <Breadcrumb style={bStyle}>
            <Item>复制课程</Item>
        </Breadcrumb>
    }
    else if (isMatch(p, "/a/teachers")) {
        return <Breadcrumb style={bStyle}>
            <Item>教师</Item>
        </Breadcrumb>
    }
    else if (isMatch(p, "/a/admin")) {
        return <Breadcrumb style={bStyle}>
            <Item>管理员</Item>
        </Breadcrumb>
    }
    else if (isMatch(p, "/a/user_groups")) {
        return <Breadcrumb style={bStyle}>
            <Item>用户组</Item>
        </Breadcrumb>
    }
    else if (m=isExactMatch(p, "/t/user_list/:name")) {
        return <Breadcrumb style={bStyle}>
            <Item><Link to="/t/user_lists">名单</Link></Item>
            <Item>{m.params.name}</Item>
        </Breadcrumb>
    }
    else if (isMatch(p, "/a/user_group_setting/:id")) {
        return <Breadcrumb style={bStyle}>
            <Item><Link to="/a/user_groups">用户组</Link></Item>
        </Breadcrumb>
    }
    else if (m=isExactMatch(p, "/a/user_group/:school/:id")) {
        return <Breadcrumb style={bStyle}>
            <Item><Link to="/a/user_groups">用户组</Link></Item>
            <Item>{m.params.id}</Item>
        </Breadcrumb>
    }
    else if (isMatch(p, "/a/word_addin")) {
        return <Breadcrumb style={bStyle}>
            <Item>插件管理</Item>
        </Breadcrumb>
    }
    else if (isMatch(p, "/a/online_devices")) {
        return <Breadcrumb style={bStyle}>
            <Item>在线设备</Item>
        </Breadcrumb>
    }
    else if (isMatch(p, "/a/experiment_box")) {
        return <Breadcrumb style={bStyle}>
            <Item>教师实验箱管理</Item>
        </Breadcrumb>
    }
    else if (isMatch(p, "/a/report_boxes")) {
        return <Breadcrumb style={bStyle}>
            <Item>作业箱</Item>
        </Breadcrumb>
    }
    else if (isMatch(p, "/a/report_boxes_view/:id")) {
        return <Breadcrumb style={bStyle}>
            <Item><Link to="/a/report_boxes">作业箱</Link></Item>
            <Item>{store.boxName}</Item>
        </Breadcrumb>
    }
    else if (m = isExactMatch(p, "/a/report_commentview/:reportId/:boxId")) {
        return <Breadcrumb style={bStyle}>
            <Item><Link to={`/a/report_boxes_view/${m.params.boxId}`}>作业箱： {store.boxName}</Link></Item>
            <Item>{store.nextReportId ? (<Link
                to={`/a/report_commentview/${store.nextReportId}/${m.params.boxId}`}>下一份：{store.nextReportName}</Link>) : "下一份：没有了"}</Item>
        </Breadcrumb>
    }
    else if (isMatch(p, "/s/download")) {
        return <Breadcrumb style={bStyle}>
            <Item><Link to="/s/download">软件下载</Link></Item>
        </Breadcrumb>
    }
    else if (isMatch(p, "/s/wordaddin")) {
        return <Breadcrumb style={bStyle}>
            <Item><Link to="/s/download">软件下载</Link></Item>
            <Item>电子实验报告WORD插件</Item>
        </Breadcrumb>
    }
    return <Breadcrumb style={bStyle}>
        <Item><Link to="/">主页</Link></Item>
    </Breadcrumb>
});

const Breadcrumbs = withRouter(({match, location, history}) => {
    // console.log(location.pathname, location.state);
    return <BreadcrumbsFromPath pathname={location.pathname}/>
});
export default Breadcrumbs;