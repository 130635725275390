const menuData = [
    {
        "title": "在线课堂",
        "children": [
            {
                "title": "我的课程",
                "url": "/s/courses"
            },
            {
                "title": "提交作业",
                "url": "/s/report/boxes"
            },
            {
                "title": "软件下载",
                "url": "/s/download"
            },
        ]
    },
    {
        "title": "资源管理",
        "url": "/s/resource/manage"
    },
    {
        "title": "我的仪器",
        "children": [
            // {
            //     "title": "硬木课堂",
            //     "url": "/s/emooc"
            // },
            // {
            //     "title": "鼎阳",
            //     "url": "/s/siglent"
            // },
            // {
            //     "title": "普源精电",
            //     "url": "/s/rigol"
            // },
        ]
    },
    {
        "title": "绑定仪器",
        "url": "/s/bind_device"
    },
    ];

module.exports = menuData;
