import axios from "axios";


export default class ConfigService {
    // 刷新七牛token
    static async refresh(){
        const ts=(new Date()).getTime(); //避免缓存
        const runtimeConfig=await axios.get(`/config/json?${ts}`).then(r=>r.data);
        Object.assign(window.config,runtimeConfig);
    }

    static reconfirm(){
        return axios.get(`/config/reconfirm`).then(r=>r.data).catch(e=>{return false});
    }
}