const menuData = [
    {
        "title": "在线课堂",
        "children": [
            {
                "title": "我的课程",
                "url": "/t/courses"
            },
            {
                "title": "学生作业",
                "url": "/t/report/boxes"
            },
        ]
    },
    {
        "title": "在线课堂(学生)",
        "children": [
            {
                "title": "提交作业",
                "url": "/s/report/boxes"
            },
        ]
    },
    // {
    //     "title": "实验管理",
    //     "children": [
    //         {
    //             "title": "实验准入",
    //             "url": "/实验准入"
    //         },
    //         {
    //             "title": "轻松排课",
    //             "url": "/schedule"
    //         },
    //         {
    //             "title": "智能派位",
    //             "url": "/智能派位"
    //         },
    //         {
    //             "title": "电子签到",
    //             "url": "/电子签到"
    //         },
    //         {
    //             "title": "电源管理",
    //             "url": "/电源管理"
    //         },
    //         {
    //             "title": "开放管理",
    //             "url": "/开放管理"
    //         },
    //         {
    //             "title": "预约管理",
    //             "url": "/预约管理"
    //         },
    //         {
    //             "title": "开放选课",
    //             "url": "/开放选课"
    //         },
    //     ]
    // },
    // {
    //     "title": "虚实结合",
    //     "children": [
    //         {
    //             "title": "智能采集",
    //             "url": "/智能采集"
    //         },
    //         {
    //             "title": "远程实验",
    //             "url": "/远程实验"
    //         },
    //     ]
    // },
    // {
    //     "title": "统计报表",
    //     "children": [
    //         {
    //             "title": "教师信息",
    //             "url": "/教师信息"
    //         },
    //         {
    //             "title": "学生信息",
    //             "url": "/学生信息"
    //         },
    //         {
    //             "title": "教室信息",
    //             "url": "/教室信息"
    //         },
    //         {
    //             "title": "出勤统计",
    //             "url": "/出勤统计"
    //         },
    //     ]
    // },
    {
        "title": "日常管理",
        "children": [
            {
                "title": "导入用户",
                "url": "/a/i_user"
            },
            {
                "title": "导入用户组",
                "url": "/a/i_user_group"
            },
            {
                "title": "查看用户",
                "url": "/a/users"
            },
            {
                "title": "查看用户组",
                "url": "/a/user_groups"
            },
        ]
    },
    // {
    //     "title": "高级配置",
    //     "children": [
    //         {
    //             "title": "界面设置",
    //             "url": "/界面设置"
    //         },
    //         {
    //             "title": "课时设置",
    //             "url": "/课时设置"
    //         },
    //     ]
    // },

];

module.exports = menuData;