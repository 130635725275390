const menuData = [
    {
        "title": "日常管理",
        "children": [
            {
                "title": "用户",
                "children": [
                    {
                        "title": "学生",
                        "url": "/a/students"
                    },
                    {
                        "title": "教师",
                        "url": "/a/teachers"
                    },{
                        "title": "管理员",
                        "url": "/a/admin"
                    },
                ]
            },
            {
                "title": "用户组",
                "url": "/a/user_groups"
            },
        ]
    },
    {
        "title": "教学管理",
        "children": [
            {
                "title": "课程管理",
                "url": "/a/courses",
            },
            {
                "title": "复制课程",
                "url": "/a/course_copy",
            },
            {
                "title": "作业箱",
                "url": "/a/report_boxes",
            },
            {
                "title": "word插件管理",
                "url": "/a/word_addin",
            },
        ]
    },
    {
        "title": "系统对接",
        "children": [
            {
                "title": "口袋实验箱绑定管理",
                "url": "/a/bind_list",
            },
            {
                "title": "在线设备",
                "url": "/a/online_devices",
            },
            {
                "title": "教师实验箱管理",
                "url": "/a/experiment_box"
            }
        ]
    },
    {
        "title": "数据统计",
        "url": "/a/data_statistics",
    },
    // {
    //     "title": "版本更新",
    //     "url": "/a/update",
    // },
    ];

module.exports = menuData;
