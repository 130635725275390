import React from "react";
import {Avatar, Col, Dropdown, Layout, Menu, Row,Divider } from 'antd';
import DataMenu from "../components/common/Menu/Menu";
import LoginService from "../services/Login";
import Breadcrumbs from "./BreadcrumbsFromPath";

const menuData = require('./MenuData');
const adminMenu= require('./adminMenu');
const teacherMenu= require('./teacherMenu');
const studentMenu= require('./studentMenu');
const readerMenu= require('./readerAdmin');
require("./BasicLayout.css");
const {Header, Content, Footer} = Layout;

const logout=()=>{
    LoginService.logout();
    window.location.hash = '/login';
};

const userMenu = (
    <Menu>
        <Menu.Item>
            <a onClick={logout}>
                退出
            </a>
        </Menu.Item>
        <Menu.Item>
            <a href="#/change_info">
                修改信息
            </a>
        </Menu.Item>
    </Menu>
);
const menus={
    'student':studentMenu,
    'teacher':teacherMenu,
    'admin':adminMenu,
    'reader':readerMenu,
};
const roles={
    'student':'学生',
    'teacher':'教师',
    'admin':'管理员',
    'reader':'督导',
};

export default function BasicLayout({children}) {
    const role=LoginService.getCurrentUserRole();
    const school=LoginService.getCurrentUserSchool();
    return (
        <div className='out_layout'>
        <Layout className="layout">
        <Header style={{background: '#DEE4FF'}}>
            <Row>
                <Col span={16}>
                    <div  className="logo">
                    <img src={config.logo}/>
                    <span className='name'>{config.name}</span>
                    </div>
                    <DataMenu
                        data={menus[role]}
                        // theme="dark"
                        mode="horizontal"
                        defaultSelectedKeys={['2']}
                        // style={{background: '#DEE4FF'}}
                    />
                </Col>
                <Col span={8}>
                    <div className="header_right">
                        <span>学校：{school||""}</span>
                        <Divider type="vertical" />
                        <span className="header_role">{roles[role]}</span>
                        <Dropdown overlay={userMenu}>
                            <a className="ant-dropdown-link" >
                                <Avatar style={{backgroundColor: '#b3b7d0', verticalAlign: 'middle'}} size="large">
                                    {LoginService.getCurrentUserShowName()}
                                </Avatar>
                            </a>
                        </Dropdown>
                    </div>
                </Col>
            </Row>
        </Header>
        <div style={{padding: '0 50px',backgroundColor:"#ffffff"}}><Breadcrumbs/></div>
        <Content style={{padding: '0 50px',backgroundColor:"#ffffff"}}>
            <div style={{background: '#fff', minHeight: 600}}>
                {children}
            </div>
        </Content>
        <Footer style={{textAlign: 'center',background:'none'}}>OLAB ©2020 Created by Limsolution</Footer>
    </Layout>
        </div>)
}
